import { InputNumber, Select } from 'antd';
import type { FilterItemOptions } from 'egenie-utils';
import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import styles from './index.less';

export const FilterItems = (): FilterItemOptions[] => {
  return [
    {
      field: 'goodsName',
      label: '名称',
      type: 'input',
    },
    {
      type: 'treeSelect',
      field: 'categoryId',
      label: '类目',
      allowClear: true,
      showSearch: true,
    },
    {
      type: 'select',
      field: 'galleryAreaId',
      label: '分区',
      allowClear: true,
      showSearch: true,
    },
  ];
};

export const TableColumns = (store) => {
  return [
    {
      key: 'operation',
      name: '操作',
      formatter: ({ row }) => (
        <div>
          <span
            className={styles.operationBtn}
            onClick={() => store.copy(row)}
          >
            复制
          </span>
          <span
            className={styles.operationBtn}
            onClick={() => store.delete(row)}
          >
            删除
          </span>
        </div>
      ),
    },
    {
      key: 'pic',
      name: '商品图片',
      formatter: ({ row }) => (
        <ImgFormatter
          height={56}
          value={row.skuPicUrl}
          width={56}
        />
      ),
    },
    {
      key: 'goodsName',
      name: '商品名称',
    },
    {
      key: 'vendorName',
      name: '档口',
    },
    {
      key: 'galleryAreaName',
      name: '分区',
    },
    {
      key: 'sellerOuterNo',
      name: '货号',
    },
    {
      key: 'colorSpecs',
      name: '规格一',
      formatter: ({ row }) => (
        <Select
          className={styles.tableSelect}
          defaultValue={row.colorSpec}
          onChange={(e) => {
            row.colorSpec = e;
            store.changeSpecs(row, e, 'colorSpec');
          }}
          onClick={(e) => e.stopPropagation()}
          options={row.colorSpecs?.map((item) => ({
            label: item,
            value: item,
          }))}
        />
      ),
    },
    {
      key: 'sizeSpecs',
      name: '规格二',
      formatter: ({ row }) => (
        row.sizeSpecs?.length ? (
          <Select
            className={styles.tableSelect}
            defaultValue={row.sizeSpec}
            onChange={(e) => {
              store.changeSpecs(row, e, 'sizeSpec');
            }}
            onClick={(e) => e.stopPropagation()}
            options={row.sizeSpecs?.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        ) : (
          <span>
            -
          </span>
        )
      ),
    },
    {
      key: 'price',
      name: '单价',
    },
    {
      key: 'sampleFeeMode',
      name: '是否免费',
    },
    {
      key: 'sampleReturnable',
      name: '是否可退',
    },
    {
      key: 'num',
      name: (
        <div>
          <span>
            下单数量
          </span>
          <InputNumber
            className={styles.BatchChangeNumber}
            min={1}
            onBlur={() => {
              if (store.batchNum) {
                store.confirmChangeNumber();
              }
            }}
            onChange={(e) => store.batchChangeNumber(e)}
            onClick={(e) => e.stopPropagation()}
            onPressEnter={() => {
              if (store.batchNum) {
                store.confirmChangeNumber();
              }
            }}

            // @ts-ignore
            parser={(value) => value.replace(/^0/g, '')
              .replace(/\D/g, '')}
            placeholder="批量填写"
          />
        </div>
      ),
      formatter: ({ row }) => (
        <InputNumber
          min={1}
          onBlur={() => store.changeNum(row, row.num)}
          onChange={(e) => {
            row.num = e;
          }}
          onClick={(e) => e.stopPropagation()}
          onPressEnter={() => store.changeNum(row, row.num)}
          onStep={() => store.changeNum(row, row.num)}
          parser={(value) => value.replace(/^0/g, '')
            .replace(/\D/g, '')}
          value={row.num}
        />
      ),
    },
  ];
};
