import type { FormInstance } from 'antd';
import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, computed, observable } from 'mobx';
import type { Address, City, District, Option, ParsingAddress, Province } from './interface';
import { EditAddressStore } from './modal/editAddress/store';
import { API } from './api';

export default class AddressManageStore {
  @observable public parent;

  @observable public editAddressStore = new EditAddressStore({ parent: this });

  @observable public galleryReceiverAddressId: number = null; // 当前选中地址

  @observable public addressTabKey = '1'; // 当前地址是常用地址还是新建地址； 1常用地址， 2新建地址

  @observable public currentAddress = null;

  @observable public addressList: Address[] = []; // 展示地址

  @observable public allAddressList: Address[] = []; // 所有地址列表

  @observable public showMoreAddress = false;

  @observable public address = ''; // 智能解析的地址字符串

  @observable public newAddRef: FormInstance;

  @observable public addressOptions: Option[] = []; // 地址option

  @observable public parsingLoading = false; // 地址解析loading

  @observable public submitLoading = false; // 提交按钮的loading

  @observable public showRoomId = null;// 展厅ID

  @computed
  public get receiverInfo() {
    if (!this.currentAddress) {
      message.warn('收货人信息不全，请补全信息');
    } else {
      const {
        receiverName,
        receiverMobile,
        province,
        city,
        district,
        receiverAddress,
      } = this.currentAddress;
      const infoList = [
        receiverName,
        receiverMobile,
        province,
        city,
        district,
        receiverAddress,
      ];
      if ((!province || province.length === 0) || (!city || city.length === 0) || (!district || district.length === 0)) {
        message.warn('收货人所在地区信息不全，请补全信息');
        return;
      }

      const { commonAddress } = this.newAddRef.getFieldsValue();
      console.log('this.newAddRef.getFieldsValue....', this.newAddRef.getFieldsValue());
      const result = infoList.some((el) => !el);
      if (result) {
        message.warn('收货人信息不全，请补全信息');
      } else {
        // return `${receiverName || ''}  ${receiverMobile || ''}  ${province || ''}${city || ''}${district || ''}${receiverAddress || ''}`;
        return {
          galleryId: this.showRoomId,
          commonAddress: this.addressTabKey === '1' ? 1 : commonAddress ? 1 : 0,
          ...this.currentAddress,
        };
      }
    }
  }

  public init = async(): Promise<void> => {
    await this.getAddress(); // 获取保存的全部地址
    this.queryProvince(); // 请求新建联系人的省份
  };

  // 获取所有地址
  @action public getAddress = async(): Promise<void> => {
    const res: BaseData<Address[]> = await request({
      url: API.queryAddressList,
      params: { galleryId: this.showRoomId },
    });
    this.allAddressList = res.data;
    const defaultAddress = res.data.filter((e) => e.defaultAddress === 1);
    if (defaultAddress.length > 0) {
      this.currentAddress = defaultAddress[0];
      this.galleryReceiverAddressId = defaultAddress[0].galleryReceiverAddressId;
      this.addressList = defaultAddress;
    }
  };

  // 设为当前地址
  @action public setAddress = (address: Address): void => {
    this.galleryReceiverAddressId = address.galleryReceiverAddressId;
    this.currentAddress = address;
    this.addressList = [address];
    this.showMoreAddress = false;
  };

  // 设为默认地址
  @action public setDefaultAddress = async(addressId: number): Promise<void> => {
    await request({
      url: API.updateDefaultAddress,
      method: 'GET',
      params: { galleryReceiveAddressId: addressId },
    });
    message.success('设置成功');
    this.setAddressAfterDefault(addressId);
  };

  // 设为默认地址后的判断
  @action public setAddressAfterDefault = async(addressId: number): Promise<void> => {
    await this.getAddress();
    if (this.showMoreAddress) {
      const address = this.allAddressList.find((item) => item.galleryReceiverAddressId === addressId);
      this.addressList = [address];
      this.galleryReceiverAddressId = address.galleryReceiverAddressId;
      this.currentAddress = address;
      this.showMoreAddress = false;
    }
  };

  // 删除地址
  @action public delAddress = (addressId: number): void => {
    Modal.confirm({
      title: '确认删除当前收货地址吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: async() => {
        await request({
          url: API.deleteAddress,
          method: 'get',
          params: { galleryReceiveAddressId: String(addressId) },
        });
        message.success('删除成功');
        await this.getAddress();
        this.addressList = this.allAddressList;
      },
    });
  };

  // 收起/展开更多地址
  @action public onToggleAddress = (): void => {
    if (this.showMoreAddress) { // 现在是展开状态
      this.addressList = [this.currentAddress];
    } else {
      this.addressList = this.allAddressList;
    }
    this.showMoreAddress = !this.showMoreAddress;
  };

  // 处理地址
  private handleAddress = (address: string[]) => {
    if (!address) {
      return {};
    }
    try {
      const province = this.addressOptions?.find((el) => el.value === address[0]) || {} as Option;
      const city = province?.children?.find((el) => el.value === address[1]) || {} as Option;
      const district = city?.children?.find((el) => el.value === address[2]);
      return {
        province: province?.label,
        provinceId: province?.value,
        city: city?.label,
        cityId: city?.value,
        district: district?.label,
        districtId: district?.value,
      };
    } catch (err) {
      console.log(err);
      return {};
    }
  };

  // 打开编辑弹窗
  public openEditModal = (id: number) => {
    this.editAddressStore.onShow(id);
  };

  // 设置ref
  @action public setNewAddRef = (ref: FormInstance): void => {
    this.newAddRef = ref;
  };

  // 地址字符串改变
  @action public onAddressChange = (e) => {
    this.address = e.target.value;
  };

  // 地址解析请求
  @action public parsingAddress = async(): Promise<void> => {
    if (!this.address) {
      return;
    }
    this.parsingLoading = true;
    try {
      const res: BaseData<ParsingAddress> = await request({
        url: API.parsingAddress,
        method: 'post',
        data: { address: this.address },
      });
      const {
        province_id,
        city_id,
        district_id,
        province,
        city,
        district,
        detail,
        phone,
        name,
      } = res?.data;

      // if (province_id && city_id && district_id) {
      await this.queryDetailAddress(province_id, city_id);
      this.newAddRef.setFieldsValue({
        name,
        phone,
        address: [
          province_id,
          city_id,
          district_id,
        ],
        detail,
      });

      // 解析成功后回显到去结算区域;
      this.currentAddress = {
        province,
        provinceId: province_id,
        city,
        cityId: city_id,
        district,
        districtId: district_id,
        receiverName: name,
        receiverAddress: detail,
        receiverMobile: phone,
      };

      this.parsingLoading = false;

      // } else {
      //   this.parsingLoading = false;
      //   message.showInfo('解析失败');
      // }
    } catch (err) {
      this.parsingLoading = false;
    }
  };

  // 请求具体省市区
  @action private queryDetailAddress = async(provinceId: string, cityId: string): Promise<void> => {
    // 请求具体地址
    const districtList = await this.queryDistrict(cityId);
    const cityList = await this.queryCity(provinceId);
    const targetCity = cityList.find((el) => el.value === `${cityId}`);
    targetCity.children = districtList;
    await this.queryProvince();
    const targetProvince = this.addressOptions.find((el) => el.value === `${provinceId}`);
    targetProvince.children = cityList;
    this.addressOptions = [...this.addressOptions];
  };

  // 请求省份
  @action public queryProvince = async(): Promise<void> => {
    const res: BaseData<Province[]> = await request({ url: API.queryProvince });
    this.addressOptions = res?.data?.map((el) => {
      return {
        label: el.provinceName,
        value: `${el.id}`,
        isLeaf: false,
      };
    });
  };

  // 请求城市
  @action public queryCity = async(provinceId: string | number): Promise<Option[]> => {
    const res: BaseData<City[]> = await request({ url: `${API.queryCity}?provinceId=${provinceId}` });
    const cityList = res?.data?.map((el) => {
      return {
        label: el.cityName,
        value: `${el.id}`,
        isLeaf: false,
      };
    });
    return Promise.resolve(cityList);
  };

  // 请求地区
  @action public queryDistrict = async(cityId: string | number): Promise<Option[]> => {
    const res: BaseData<District[]> = await request({ url: `${API.queryDistrict}?cityId=${cityId}` });
    const districtList = res?.data?.map((el) => {
      return {
        label: el.districtName,
        value: `${el.id}`,
        isLeaf: true,
      };
    });
    return Promise.resolve(districtList);
  };

  // 动态加载地区
  public loadData = async(selectedOptions: Option[]): Promise<void> => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    if (selectedOptions.length === 1) {
      targetOption.children = await this.queryCity(targetOption.value);
      targetOption.loading = false;
      const current = this.addressOptions.find((e) => e.value === targetOption.value);
      current.children = targetOption.children;
      current.loading = false;
      this.addressOptions = [...this.addressOptions];
    } else if (selectedOptions.length === 2) {
      const district = await this.queryDistrict(targetOption.value);
      const selectedProvince = selectedOptions[0];
      const cityList = this.addressOptions.find((e) => e.value === selectedProvince.value).children;
      const current = cityList.find((e) => e.value === targetOption.value);
      current.children = district;
      current.loading = false;
      this.addressOptions = [...this.addressOptions];
    }
  };

  // 地址tab切换
  @action public changeAddressTab = (tabKey: string) => {
    this.addressTabKey = tabKey;

    // tab切换，清空运费列表
    this.currentAddress = null;
    if (tabKey === '1') {
      const address = this.allAddressList.find((item) => item.galleryReceiverAddressId === this.galleryReceiverAddressId);
      this.currentAddress = address;
    } else {
      this.parserAddress();
    }
  };

  // 监听手动创建地址form, 实时回显至底部结算区
  @action public onNewAddressChange = (values): void => {
    try {
      const {
        address,
        phone,
        detail,
        name,
      } = this.newAddRef?.getFieldsValue();
      const addressInfo = this.handleAddress(address);
      this.currentAddress = {
        ...addressInfo,
        receiverName: name,
        receiverAddress: detail,
        receiverMobile: phone,
      };

      if (values?.address) {
        this.parserAddress();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 解析当前地址
  @action public parserAddress = (): void => {
    const values = this.newAddRef?.getFieldsValue();
    if (!values) {
      return;
    }

    // 地址存在则请求运费
    if (values?.address && values.address?.length > 0) {
      const {
        address,
        phone,
        detail,
        name,
      } = values;
      try {
        const addressInfo = this.handleAddress(address);
        this.currentAddress = {
          ...addressInfo,
          receiverName: name,
          receiverAddress: detail,
          receiverMobile: phone,
        };
      } catch (err) {
        console.log(err);
      }

      // 地址不存在则清空运费信息
    } else {
      this.currentAddress = null;
    }
  };

  // 新建常用收货地址
  @action public createCommonAddress = async(): Promise<BaseData<string>> => {
    const {
      name,
      phone,
      address,
      detail,
    } = this.newAddRef?.getFieldsValue();
    const addressInfo = this.handleAddress(address);
    const params = {
      receiverName: name,
      receiverMobile: phone,
      receiverAddress: detail,
      defaultAddress: 0,
      ...addressInfo,
    };
    const res: BaseData<string> = await request({
      url: API.addReceiverAddress,
      method: 'post',
      data: params,
    });
    return Promise.resolve(res);
  };

  public editAddressWriteBack = (params: Partial<Address>): void => {
    const {
      galleryReceiverAddressId,
      receiverAddress,
      receiverMobile,
      receiverName,
      province,
      city,
      district,
    } = params;
    const idx = this.allAddressList.findIndex((item) => item.galleryReceiverAddressId === galleryReceiverAddressId);
    if (idx > -1) {
      this.allAddressList[idx] = {
        ...this.allAddressList[idx],
        receiverName,
        receiverMobile,
        receiverAddress,
        province,
        city,
        district,
      };
      const index = this.addressList.findIndex((item) => item.galleryReceiverAddressId === galleryReceiverAddressId);
      if (index > -1) {
        this.addressList[idx] = {
          ...this.addressList[idx],
          receiverName,
          receiverMobile,
          receiverAddress,
          province,
          city,
          district,
        };
      }
      if (this.galleryReceiverAddressId === galleryReceiverAddressId) {
        this.currentAddress = this.allAddressList[idx];
      }
    }
  };

  @action
  public reset = () => {
    this.newAddRef.resetFields();
    this.galleryReceiverAddressId = null;
    this.addressTabKey = '1';
    this.currentAddress = null;
    this.addressList = [];
    this.allAddressList = [];
    this.showMoreAddress = false;
    this.address = '';
    this.parsingLoading = false;
    this.submitLoading = false;
    this.showRoomId = null;
  };
}
