import { observable, action } from 'mobx';
import React from 'react';
import type { FormInstance } from 'antd';
import type ParentStore from '../store';

class Store {
  constructor({ parent }) {
    this.parent = parent;
  }

  public parent: ParentStore;

  public formRef = React.createRef<FormInstance>();

  @observable public visible = false;

  @action public openModal = () => {
    this.visible = true;
  };

  @action public closeModal = () => {
    this.visible = false;
    this.formRef.current.resetFields();
  };

  public onModify = () => {
    this.formRef.current.validateFields().then((data) => {
      const { cooperateMode, cooperateUsefulTime } = data;
      const selectedIds = [...this.parent.gridModel.selectedIds];
      this.parent.gridModel.rows = this.parent.gridModel.rows.map((item) => {
        if (selectedIds.includes(item.goodsId)) {
          return {
            ...item,
            cooperateMode,
            cooperateUsefulTime: cooperateMode === 0 ? cooperateUsefulTime : [
              null,
              null,
            ],
          };
        } else {
          return { ...item };
        }
      });
      
      this.parent.gridModel.resetAll();
      this.closeModal();
    });
  };
}

export default Store;
