import React from 'react';
import { Modal, Form, Select, DatePicker } from 'antd';
import { observer } from 'mobx-react';
import { cooperateModeOptions, disabledDate } from '../constant';
import type ModifyStore from './store';

const { Item } = Form;
const { RangePicker } = DatePicker;
const FORM_LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

export const ModifyModal: React.FC<{ store: ModifyStore; }> = observer((props) => {
  const { formRef, visible, closeModal, onModify } = props.store;
  return (
    <Modal
      maskClosable={false}
      onCancel={closeModal}
      onOk={onModify}
      open={visible}
      title="批量修改"
    >
      <Form
        ref={formRef}
        {...FORM_LAYOUT}
      >
        <Item
          label="合作方式"
          name="cooperateMode"
          rules={[{ required: true }]}
        >
          <Select options={cooperateModeOptions}/>
        </Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) => prevValues.cooperateMode !== curValues.cooperateMode}
        >
          {({ getFieldValue }) => {
            return getFieldValue('cooperateMode') === 0 ? (
              <Item
                label="合作时间"
                name="cooperateUsefulTime"
                rules={[{ required: true }]}
              >
                <RangePicker
                  disabledDate={disabledDate}
                  showTime
                />
              </Item>
            ) : null;
          }}
        </Form.Item>
      
      </Form>
    </Modal>
  );
});
