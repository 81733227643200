import { Button, Modal, Input, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { SetSubareaStore } from './store';

@observer
export class SetSubareaModal extends React.Component<{ store?: SetSubareaStore; }> {
  private renderEditNode = (name: string, onChange: (e) => void, onAdd: (e) => void, onCancel: () => void, id?: string): JSX.Element => {
    return (
      <>
        <Input
          autoFocus
          className={styles.input}
          maxLength={6}
          onChange={onChange}
          placeholder="请输入分区名称"
          value={name}
        />
        <Button
          className={styles.ml8}
          onClick={onAdd}
          type="primary"
        >
          {id ? '确认' : '创建'}
        </Button>
        <Button
          className={styles.ml8}
          onClick={onCancel}
          type="primary"
        >
          取消
        </Button>
      </>
    );
  };

  render() {
    const { visible, name, showNewEdit, list, areaId, loading,
      onChangeSelectArea, onSave, changeVisible, handleChangeName, handleAdd, changeNewEditVisible, handleDelete, handleEdit, handleCancelEdit, handleCancelNewEdit, handleShowOrHidden } = this.props.store;

    return (
      <Modal
        className={styles.setSubareaModal}
        confirmLoading={loading}
        destroyOnClose
        okText="保存"
        onCancel={() => changeVisible(false)}
        onOk={onSave}
        open={visible}
        title="设置分区"
        width={500}
      >
        {showNewEdit
          ? this.renderEditNode(
            name,
            handleChangeName,
            () => handleAdd(),
            handleCancelNewEdit
          )
          : (
            <Button
              onClick={() => changeNewEditVisible(true)}
              type="primary"
            >
              新建分区
            </Button>
          )}
        <div className={styles.content}>
          {list?.map((item, index) => {
            return (
              <div
                className={styles.areaItem}
                key={item.galleryAreaId}
              >
                <Radio
                  checked={areaId === item.galleryAreaId}
                  className={styles.radio}
                  onChange={() => onChangeSelectArea(item.galleryAreaId)}
                />
                {item.isEdit
                  ? this.renderEditNode(
                    item.newName || item.galleryAreaName,
                    (e) => handleChangeName(e, item.galleryAreaId),
                    (e) => handleAdd(item.galleryAreaId),
                    () => handleCancelEdit(index),
                    item.galleryAreaId
                  )
                  : (
                    <>
                      <span className={styles.ml8}>
                        {item.galleryAreaName}
                      </span>
                      {item.galleryAreaId !== '0' && (
                        <>
                          <i
                            className={`icon-edit ${styles.editIcon}`}
                            onClick={() => handleEdit(index)}
                          />
                          <i
                            className={item.visible === 1 ? `icon-kq ${styles.hideIcon}` : `icon-gb ${styles.hideIcon}`}
                            onClick={() => handleShowOrHidden(index)}
                          />
                          <i
                            className={`icon-home_sj ${styles.deleteIcon}`}
                            onClick={() => handleDelete(item.galleryAreaId)}
                          />
                        </>
                      )}
                    </>
                  )}
              </div>
            );
          })}
        </div>
      </Modal>
    );
  }
}
