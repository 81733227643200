import { observable, action } from 'mobx';
import type { BaseData, PaginationData } from 'egenie-common';
import { request, mapOptions } from 'egenie-common';
import _ from 'lodash';
import { message } from 'antd';
import moment from 'moment';
import { EgGridModel, NormalProgramme } from 'egenie-utils';
import { FILTER_SET, STYLE_COLUMNS } from './constant';

class Store {
  constructor({ parent }) {
    this.parent = parent;
  }

  public parent;

  public getProviderList = (tenantName) => {
    const data = {
      page: 1,
      pageSize: 100,
    };
    tenantName && Object.assign(data, { tenantName });
    request<PaginationData<Record<string, string>>>({
      url: '/api/gallery/rest/clothingAgentVendorRelation/vendorDictPage',
      method: 'POST',
      data,
    }).then((res) => {
      const vendorOptions = mapOptions(res.data.list, 'id', 'name');
      this.filterSet.filterItems.addDict({ vendorTenantIds: vendorOptions });
    });
  };

  public filterSet = new NormalProgramme({
    count: 4,
    filterItems: FILTER_SET(this),
    handleSearch: () => {
      return this.getStylePage(1, 50);
    },
  });

  public egGridModel = new EgGridModel({
    columns: STYLE_COLUMNS(this).map((item) => ({
      ...item,
      resizable: true,
    })),
    rowHeight: 72,
    primaryKeyField: 'goodsId',
    showNoSearchEmpty: true,
    showRefresh: false,
    api: {
      onPageChange: (page, pageSize) => {
        this.getStylePage(page, pageSize);
      },
      onShowSizeChange: (page, pageSize) => {
        this.getStylePage(1, pageSize);
      },
    },
  });

  public initOPtions = () => {
    // 获取供应商下拉列表
    this.getProviderList('');
  };

  public getStylePage = (page, pageSize) => {
    this.egGridModel.loading = true;
    const queryParams = this.filterSet.filterItems.params;
    if (queryParams.vendorTenantIds) {
      queryParams.vendorTenantIds = [queryParams.vendorTenantIds];
    }

    return request<PaginationData>({
      url: '/api/gallery/rest/clothingAgentGoodsRelation/canCooperateGoodsPage',
      data: {
        page,
        pageSize,
        ...queryParams,
      },
      method: 'POST',
    }).then((res) => {
      const { page, pageSize, list, totalCount } = res.data;
      this.egGridModel.rows = list;
      this.egGridModel.current = page;
      this.egGridModel.total = totalCount;
      this.egGridModel.pageSize = pageSize;
    })
      .finally(() => {
        this.egGridModel.loading = false;
      });
  };

  // 添加
  @action public addStyles = (rows) => {
    const addRows = Array.isArray(rows) ? rows : [rows];
    const actualAddRows = _.differenceBy(addRows, this.parent.gridModel.rows, 'goodsId').map((item) => ({
      ...item,
      cooperateMode: 0,
      cooperateUsefulTime: [
        moment().startOf('day'),
        moment().endOf('day'),
      ],
    }));
    this.parent.gridModel.rows = this.parent.gridModel.rows.concat(actualAddRows);
  };

  // 删除
  @action public deleteStyles = (rows) => {
    const deleteRows = Array.isArray(rows) ? rows : [rows];
    this.parent.gridModel.rows = _.differenceBy(this.parent.gridModel.rows, deleteRows, 'goodsId');
  };

  @observable public visible = false;

  @action public openModal = () => {
    this.visible = true;
    this.initOPtions();
    this.getStylePage(1, 50);
  };

  public batchAdd = () => {
    if (this.egGridModel.selectRows.length <= 0) {
      message.warn('请选择商品');
      return;
    }
    this.addStyles(this.egGridModel.selectRows);
    this.egGridModel.resetAll();
  };

  @action public closeModal = () => {
    this.egGridModel.rows = [];
    this.visible = false;
  };
}

export default Store;
