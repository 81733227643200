import { Button, Card } from 'antd';
import { NormalProgrammeComponent, EgGrid, FullModal } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { BatchReturnStore } from './store';

export const BatchReturn: React.FC<{ store: BatchReturnStore; }> = observer((props) => {
  const { visible, toggleModal, programme, gridModel, submitReturn, confirmLoading } = props.store;
  return (
    <FullModal
      onCancel={() => {
        toggleModal(false, []);
      }}
      operation={null}
      title="批量退货"
      visible={visible}
    >
      <div className={styles.modalContent}>
        <Card className={styles.card}>
          <NormalProgrammeComponent store={programme}/>
        </Card>
        <div className={styles.gridWrapper}>
          <EgGrid store={gridModel}/>
        </div>
        <div className={styles.footer}>
          <div>
            已选择商品总数
            <span className={styles.total}>
              {gridModel.rows?.length}
            </span>
            {' '}
            件
          </div>
          <div>
            <Button
              loading={confirmLoading}
              onClick={submitReturn}
              type="primary"
            >
              确认退货
            </Button>
            <Button
              className={styles.cancelBtn}
              onClick={() => {
                toggleModal(false, []);
              }}
            >
              取消
            </Button>
          </div>
        </div>
      </div>
    </FullModal>
  );
});
