import { Button, Card, Spin } from 'antd';
import { FullModal, MainSubStructure, NormalProgrammeComponent, Permission } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import type { BatchOrderStore } from './store';
import { AddressManager } from './addressManager/addressManager';

@observer
export class BatchOrder extends React.Component<{ store?: BatchOrderStore; }> {
  render() {
    const { store } = this.props;
    return (
      <FullModal
        onCancel={() => store.changeVisible(false)}
        style={{ background: '#f2f2f2' }}
        title="批量下单"
        visible={store.visible}
      >
        <Spin
          spinning={store.pageLoading}
        >
          <div className={styles.content}>
            <Card>
              <AddressManager addressManageStore={store.addressManageStore}/>
            </Card>
            <Card className={styles.searchContainer}>
              <NormalProgrammeComponent store={store.normalProgramme}/>
            </Card>
            <div className={styles.table}>
              <MainSubStructure store={store.mainSubStructureModel}/>
            </div>
            <div className={styles.footer}>
              <div>
                <span>
                  已选商品总数：
                  {store.totalNum}
                  件
                </span>
                <span className={styles.message}>
                  买家留言：
                  <input
                    className={styles.input}
                    maxLength={200}
                    onBlur={(event) => {
                      store.message = event.target.value;
                    }}
                    placeholder="请输入买家留言，限200字"
                  />
                </span>
              </div>
              <div>
                <Permission permissionId="75_375">
                  <Button
                    disabled={!store.initialGoods.length || store.initialGoods.some((item) => !item.num)}
                    loading={store.loading}
                    onClick={() => store.save()}
                    type="primary"
                  >
                    确认下单
                  </Button>
                </Permission>
                <Button
                  className={styles.cancelBtn}
                  onClick={() => store.changeVisible(false)}
                >
                  取消
                </Button>
              </div>
            </div>
          </div>
        </Spin>
      </FullModal>
    );
  }
}
