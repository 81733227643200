import type { BaseData } from 'egenie-utils';
import { EgGridModel, ImgFormatter, request } from 'egenie-utils';
import { observable, action } from 'mobx';
import React from 'react';
import api from '../../../../utils/api';
import type ParentStore from '../../store';

export default class ArriveLogStore {
  constructor({ parent }) {
    this.parentStore = parent;
    this.init();
  }

  @observable private parentStore: ParentStore;

  @observable public logGrid: EgGridModel;

  @observable public goodsId: number;

  public clearRow = () => {
    this.logGrid.rows = [];
  };

  public init = (): void => {
    this.logGrid = new EgGridModel({
      columns: [
        {
          key: 'pic',
          name: '图片',
          width: 90,
          formatter: ({ row }) => {
            return (
              <ImgFormatter
                height={48}
                value={row?.pic}
                width={48}
              />
            );
          },
        },
        {
          key: 'goodsName',
          name: '商品名称',
          formatter: ({ row }) => {
            return (
              <div title={row.goodsName}>
                {row.goodsName}
              </div>
            );
          },
        },
        {
          key: 'uniqueCode',
          name: '唯一码',
        },
        {
          key: 'goodsSkuNo',
          name: 'sku编码',
          width: 150,
        },
        {
          key: 'color',
          name: '颜色',
        },
        {
          key: 'size',
          name: '尺码',
        },
        {
          key: 'num',
          name: '数量',
        },
        {
          key: 'arrivalTime',
          name: '网仓到货日期',
          width: 150,
        },
        {
          key: 'createTime',
          name: '展厅到货日期',
          width: 150,
        },
        {
          key: 'returnDate',
          name: '退货日期',
          width: 150,
        },
        {
          key: 'stockOuted',
          name: '是否出库',
        },
        {
          key: 'returned',
          name: '是否退货',
        },
      ].map((el) => {
        return {
          resizable: true,
          ...el,
        };
      }),
      primaryKeyField: 'uniqueCode',
      rows: [],
      showNormalEmpty: true,
      showPager: false,
      showCheckBox: false,
      api: {},
    });
  };
}
