export const API = {
  // 地址管理
  queryAddressById: '/api/gallery/rest/sale/order/queryReceiveAddressById', // 根据id查询地址详情
  addReceiverAddress: '/api/gallery/rest/sale/order/saveReceiveAddress', // 添加
  deleteAddress: '/api/gallery/rest/sale/order/deleteReceiveAddress', // 删除
  updateReceiverAddress: '/api/gallery/rest/sale/order/saveReceiveAddress', // 修改
  updateDefaultAddress: '/api/gallery/rest/sale/order/defaultReceiveAddress', // 设置是否为默认地址
  queryAddressList: '/api/gallery/rest/sale/order/queryReceiveAddressList', // 查询所有地址
  queryMallDefaultAddress: '/api/mall/rest/receiverAddress/queryMallDefaultAddress', // 查询默认地址
  findProvince: '/api/mall/rest/receiverAddress/findProvince', // 省
  findCityByProvinceId: '/api/mall/rest/receiverAddress/findCityByProvinceId', // 市
  findDistrictByCityId: '/api/mall/rest/receiverAddress/findDistrictByCityId', // 区
  queryProvince: '/api/infrastructure/region/provinces', // 获取省份
  queryCity: '/api/infrastructure/region/cities', // 获取城市
  queryDistrict: '/api/infrastructure/region/districts', // 获取区域
  parsingAddress: '/api/gallery/rest/sale/order/addressParsing', // 地址解析
};
