import React from 'react';
import { Button } from 'antd';
import { Observer } from 'mobx-react';
import _ from 'lodash';
import type { FilterItemOptions, ColumnType } from 'egenie-utils';
import { ImgFormatter } from 'egenie-utils';
import styles from './index.less';

export const FILTER_SET = (context): FilterItemOptions[] => {
  return [
    {
      type: 'select',
      label: '供应商',
      field: 'vendorTenantIds',
      onSearchCallback: _.debounce(context.getProviderList, 300),
    },
    {
      type: 'treeSelect',
      label: '类目',
      field: 'categoryId',
    },
    {
      type: 'input',
      label: '货号',
      field: 'sellerOuterNo',
    },
  ];
};

export const STYLE_COLUMNS = (context): ColumnType => {
  return (
    [
      {
        key: 'operation',
        name: '操作',
        width: 30,
        frozen: true,
        formatter: ({ row }) => {
          return (
            <Observer>
              {
                () => {
                  return context.parent.gridModel.rows.some((item) => item.goodsId === row.goodsId) ? (
                    <Button
                      disabled
                      size="small"
                      type="link"
                    >
                      已添加
                    </Button>
                  ) : (
                    <a
                      onClick={() => {
                        context.addStyles(row);
                      }}
                      type="link"
                    >
                      添加
                    </a>
                  );
                }
              }
            </Observer>
          );
        },
      },
      {
        key: 'goodsName',
        name: '款式信息',
        width: 280,
        formatter: ({ row }) => {
          return (
            <div className={styles.infoWrap}>
              <ImgFormatter
                height={56}
                src={row.mainPicUrl}
                width={56}
              />
              <div className={styles.nameWrap}>
                <div className={styles.name}>
                  {row.goodsName}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        key: 'shopName',
        name: '档口信息',
        width: 280,
        formatter: ({ row }) => {
          return (
            <div className={styles.vendorWrap}>
              <div className={styles.vendorName}>
                {row.shopName}
              </div>
              <div>
                {Array.isArray(row.shopTagList) && row.shopTagList.length > 0 ? row.shopTagList.map((item) => item.name).join('/') : ''}
              </div>
            </div>
          );
        },
      },
      {
        key: 'sellerOuterNo',
        name: '货号',
        width: 180,
      },
      {
        key: 'goodsSourceTypeName',
        name: '款式来源',
        width: 50,
      },
      {
        key: 'cityName',
        name: '货源地',
        width: 80,
      },
      {
        key: 'returnRate',
        name: '退货比例',
        width: 40,
        formatter: ({ row }) => {
          return (
            <span>
              {row.returnRate}
              %
            </span>
          );
        },
      },
      {
        key: 'minWholePrice',
        name: '供货价',
        formatter: ({ row }) => {
          return (
            <span>
              ¥
              {row.minWholePrice}
            </span>
          );
        },
      },
    ]);
};

