import React from 'react';
import { Modal, Button } from 'antd';
import { observer } from 'mobx-react';
import { NormalProgrammeComponent, EgGrid } from 'egenie-utils';
import type Store from './store';

export const AddModal: React.FC<{ store: Store; }> = observer((props) => {
  const { visible, egGridModel, filterSet, closeModal, batchAdd } = props.store;
  return (
    <Modal
      centered
      footer={(
        <Button onClick={() => {
          closeModal();
        }}
        >
          关闭
        </Button>
      )}
      maskClosable={false}
      onCancel={closeModal}
      open={visible}
      title="新增选品"
      width="80%"
    >
      <NormalProgrammeComponent store={filterSet}/>
      <div style={{ margin: '8px 0' }}>
        <Button
          onClick={() => {
            batchAdd();
          }}
          size="small"
        >
          批量添加
        </Button>
      </div>
      <div style={{ height: '600px' }}>
        <EgGrid store={egGridModel}/>
      </div>
    </Modal>
  );
});
