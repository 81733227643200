import { observable, action } from 'mobx';
import { EgGridModel, request } from 'egenie-utils';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { COLUMNS, TIME_FORMAT } from './constant';
import _ from 'lodash';
import AddModalStore from './addModal/store';
import ModifyStore from './modifyModal/store';

class Store {
  constructor({ parent }) {
    this.parent = parent;
  }

  @observable public submitLoading = false;

  public addModalStore = new AddModalStore({ parent: this });// 添加选品

  public modifyStore = new ModifyStore({ parent: this });// 批量修改选品

  public parent;
  
  public gridModel = new EgGridModel({
    showNormalEmpty: true,
    showPagination: false,
    showSelectedTotal: true,
    showReset: true,
    showEmpty: false,
    showRefresh: false,
    columns: COLUMNS(this),
    rows: [],
    primaryKeyField: 'goodsId',
    sortByLocal: false,
    showCheckBox: true,
    api: {
      onRowClick: (rowId, row) => {
        const { rows } = this.gridModel;
        this.gridModel.rows = rows.map((item) => ({
          ...item,
          edit: item.goodsId === rowId,
        }));
        return {
          ...row,
          edit: true,
        };
      },
    },
  });

  public batchModify = () => {
    if (this.gridModel.selectRows.length <= 0) {
      message.warn('请选择商品');
      return;
    }
    this.modifyStore.openModal();
  };

  public batchDelete = () => {
    if (this.gridModel.selectRows.length <= 0) {
      message.warn('请选择商品');
      return;
    }
    this.addModalStore.deleteStyles(this.gridModel.selectRows);
    this.gridModel.resetAll();
  };

  @observable public visible = false;

  @action public openModal = () => {
    this.visible = true;
  };

  @action public closeModal = () => {
    this.visible = false;
    this.gridModel.rows = [];
  };

  // 提交选品
  public selectCooperateProduct = () => {
    if (this.gridModel.rows.length < 1) {
      message.warn('请选择商品');
      return;
    }
    this.submitLoading = true;
    const data = this.gridModel.rows.map((item) => {
      const { cooperateMode, cooperateUsefulTime, goodsId } = item;

      const submitData = {
        cooperateMode,
        goodsId,
      };

      if (cooperateMode === 0) {
        const [
          cooperateUsefulStartTime,
          cooperateUsefulEndTime,
        ] = cooperateUsefulTime;
        Object.assign(submitData, {
          cooperateUsefulStartTime: cooperateUsefulStartTime.format(TIME_FORMAT),
          cooperateUsefulEndTime: cooperateUsefulEndTime.format(TIME_FORMAT),
        });
      }

      return submitData;
    });

    request<BaseData>({
      url: '/api/gallery/rest/clothingAgentGoodsRelation/choose',
      method: 'POST',
      data,
    }).then((res) => {
      message.success(res.data || '操作成功');
      this.closeModal();
      this.parent.grid.gridModel.onQuery();
    })
      .finally(() => {
        this.submitLoading = false;
      });
  };
}

export default Store;

