import React from 'react';
import moment from 'moment';
import { DatePicker, Select } from 'antd';
import type { ColumnType } from 'egenie-utils';
import { ImgFormatter } from 'egenie-utils';

export const cooperateModeOptions = [
  {
    label: '联营',
    value: 0,
  },
  {
    label: '买断',
    value: 1,
  },
];

const cooperateModeMap = {
  '0': '联营',
  '1': '买断',
};

export const disabledDate = (current) => {
  return current && current < moment().startOf('day');
};

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const COLUMNS = (context): ColumnType => {
  return ([
    {
      key: 'operation',
      name: '操作',
      width: 120,
      frozen: true,
      formatter: ({ row }) => {
        return (
          <a
            onClick={() => {
              context.addModalStore.deleteStyles(row);
            }}
            type="link"
          >
            删除
          </a>
        );
      },
    },
    {
      key: 'goodsName',
      name: '商品名称',
      width: 200,
    },
    {
      key: 'mainPicUrl',
      name: '图片',
      width: 60,
      formatter: ({ row }) => {
        return (<ImgFormatter src={row.mainPicUrl}/>);
      },
    },
    {
      key: 'sellerOuterNo',
      name: '货号',
      width: 200,
    },
    {
      key: 'minWholePrice',
      name: '供货价',
      width: 30,
    },
    {
      key: 'shopName',
      name: '供应商',
      width: 200,
    },
    {
      key: 'cooperateMode',
      name: '合作方式',
      width: 200,
      formatter: ({ row }) => {
        return row.edit ? (
          <Select
            defaultValue={row.cooperateMode}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onSelect={(value) => {
              context.gridModel.rows = context.gridModel.rows.map((item) => {
                if (item.goodsId === row.goodsId) {
                  return {
                    ...item,
                    cooperateMode: value,
                    edit: false,
                  };
                } else {
                  return { ...item };
                }
              });
            }}
            
            options={cooperateModeOptions}
          />
        ) : (
          <span>
            {cooperateModeMap[row.cooperateMode]}
          </span>
        );
      },
    },
    {
      key: 'cooperateUsefulTime',
      name: '合作时间',
      formatter: ({ row }) => {
        return row.cooperateMode === 0 ? (row.edit ? (
          <DatePicker.RangePicker
            allowClear={false}
            allowEmpty={[
              false,
              false,
            ]}
            defaultOpen
            defaultValue={row.cooperateUsefulTime}
            disabledDate={disabledDate}
            onChange={(val) => {
              context.gridModel.rows = context.gridModel.rows.map((item) => {
                if (item.goodsId === row.goodsId) {
                  return {
                    ...item,
                    cooperateUsefulTime: val,
                    edit: false,
                  };
                } else {
                  return { ...item };
                }
              });
            }}

            onClick={(e) => {
              e.stopPropagation();
            }}
  
            showTime
            value={row.cooperateUsefulTime}
          />
        ) : (
          <span>
            {row.cooperateUsefulTime[0].format(TIME_FORMAT)}
            至
            {row.cooperateUsefulTime[1].format(TIME_FORMAT)}
          </span>
        )) : (
          <span>
            -
          </span>
        );
      },
    },
  ]);
};
