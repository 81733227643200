enum AddStatus {
  '待确认',
  '已加入',
  '取消加入'
}

enum ShelveStatus {
  '已下架',
  '已上架',
  '待上架',
}

enum ApproveStatus {
  '未审核',
  '审核通过',
  '审核驳回'
}

enum GoodsStatus {
  '仓库中' = 2,
  '出售中' = 3
}

enum ShelveOptions {
  'unshelve',
  'shelve'
}

interface Showroom {
  galleryId: number;
  galleryName: string;
  [propsName: string]: any;
}

export { AddStatus, ShelveStatus, ApproveStatus, GoodsStatus, Showroom, ShelveOptions };
