import { InputNumber } from 'antd';
import type { FilterItemOptions } from 'egenie-utils';
import { ImgFormatter } from 'egenie-utils';
import React from 'react';
import styles from './index.less';
import type { BatchReturnStore } from './store';

export const FILTER_ITEMS: FilterItemOptions[] = [
  {
    field: 'goodsName',
    label: '名称',
    type: 'input',
  },
  {
    type: 'select',
    field: 'vendorName',
    label: '档口',
  },
  {
    type: 'select',
    field: 'galleryAreaId',
    label: '分区',
    allowClear: true,
    showSearch: true,
  },
];

export const COLUMNS = (store: BatchReturnStore) => {
  return [
    {
      key: 'operation',
      name: '操作',
      frozen: true,
      width: 50,
      formatter: ({ row }) => (
        <div>
          <span
            className={styles.operationBtn}
            onClick={() => store.delete(row)}
          >
            删除
          </span>
        </div>
      ),
    },
    {
      key: 'pic',
      name: '商品图片',
      formatter: ({ row }) => (
        <ImgFormatter value={row.mainUrl}/>
      ),
    },
    {
      key: 'goodsName',
      name: '商品名称',
      width: 380,
    },
    {
      key: 'vendorName',
      name: '档口',
      width: 150,
    },
    {
      key: 'galleryAreaName',
      name: '分区',
      width: 150,
    },
    {
      key: 'colorSpec',
      name: '规格一',
      width: 150,
    },
    {
      key: 'sizeSpec',
      name: '规格二',
      width: 150,
    },
    {
      key: 'warehouseBinNo',
      width: 200,
      name: '库位',
    },
    {
      key: 'skuReturnNum',
      width: 200,
      name: '最大可退数量',
    },
    {
      key: 'num',
      name: '退货数量',
      width: 120,
      formatter: ({ row }) => (
        <InputNumber
          max={row.skuReturnNum}
          min={1}
          onBlur={() => store.changeNum(row, row.num)}
          onChange={(e) => {
            row.num = e;
          }}
          onClick={(e) => e.stopPropagation()}
          onPressEnter={() => store.changeNum(row, row.num)}
          onStep={() => store.changeNum(row, row.num)}
          parser={(value) => value.replace(/^0/g, '')
            .replace(/\D/g, '')}
          value={row.num}
        />
      ),
    },
    {
      key: 'arrivalTime',
      name: '网仓到货日期',
      width: 150,
    },
    {
      key: 'arrivalDate',
      name: '展厅到货日期',
      width: 150,
    },
    {
      key: 'returnDate',
      name: '退货日期',
      width: 150,
    },
  ];
};
