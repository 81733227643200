import { LoadingOutlined } from '@ant-design/icons';
import { Button, Card, Space, Spin, Tabs } from 'antd';
import { ExportModal, MainSubStructure, NormalProgrammeComponent, Permission } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import api from '../../utils/api';
import { BatchOrder } from './components/batchOrder';
import { BatchReturn } from './components/batchReturn';
import { PrintModal } from './components/print';
import { SetSubareaModal } from './components/setSubarea/index';
import styles from './index.less';
import ShowroomStore from './store';
import { SelectStyle } from './selectStyle';

const store = new ShowroomStore();
const antIcon = (
  <LoadingOutlined
    spin
    style={{ fontSize: 12 }}
  />
);

// 批量操作按钮
const batchBtns = (joinStatus: string, tenantType: string) => {
  const {
    handleClickPrint,
    handleClickExport,
    handleClickBatchAdd,
    handleClickSubarea,
    handleBatchReturn,
    handleBatchRemove,
    batchShelf,
    batchUnshelf,
    batchAddCart,
    batchCancelJoin,
  } = store;
  return (
    <>
      {joinStatus === '-1' && (
        <>
          <Permission permissionId="75_6">
            <Button onClick={handleClickExport}>
              批量导出
            </Button>
          </Permission>
          <Permission permissionId="75_501">
            <Button onClick={handleClickPrint}>
              批量打印
            </Button>
          </Permission>
          <Permission permissionId="75_373">
            <Button onClick={handleClickBatchAdd}>
              批量加入
            </Button>
          </Permission>

          <Permission permissionId="75_376">
            <Button onClick={handleBatchReturn}>
              批量退货
            </Button>
          </Permission>
          <Permission permissionId="75_371">
            <Button onClick={handleClickSubarea}>
              批量设置分区
            </Button>
          </Permission>
          <Permission permissionId="75_372">
            <Button onClick={batchAddCart}>
              批量加购
            </Button>
          </Permission>
          <Permission permissionId="75_604">
            <Button onClick={batchShelf}>
              批量上架
            </Button>
          </Permission>
          <Permission permissionId="75_4051">
            <Button onClick={batchUnshelf}>
              批量下架
            </Button>
          </Permission>
        
        </>
      )}
      {
        joinStatus === '1' && (
          <>
            <Permission permissionId="75_501">
              <Button onClick={handleClickPrint}>
                批量打印
              </Button>
            </Permission>
            <Permission permissionId="75_376">
              <Button onClick={handleBatchReturn}>
                批量退货
              </Button>
            </Permission>
            <Permission permissionId="75_371">
              <Button onClick={handleClickSubarea}>
                批量设置分区
              </Button>
            </Permission>
            <Permission permissionId="75_372">
              <Button onClick={batchAddCart}>
                批量加购
              </Button>
            </Permission>
            <Permission permissionId="75_604">
              <Button onClick={batchShelf}>
                批量上架
              </Button>
            </Permission>
            <Permission permissionId="75_4051">
              <Button onClick={batchUnshelf}>
                批量下架
              </Button>
            </Permission>
            <Permission permissionId="75_374">
              <Button onClick={batchCancelJoin}>
                批量取消加入
              </Button>
            </Permission>
          </>
        )
      }

      {
        (joinStatus === '0' || joinStatus === '2' && tenantType !== '100027') && (
          <Permission permissionId="75_373">
            <Button onClick={handleClickBatchAdd}>
              批量加入
            </Button>
          </Permission>
        )
      }
      {
        tenantType !== '100027' ? (
          <Permission permissionId="75_6803">
            <Button onClick={handleBatchRemove}>
              批量移出
            </Button>
          </Permission>
        ) : null
      }
      {
        tenantType.includes('100027') ? (
          <Permission permissionId="430_6800">
            <Button
              onClick={() => {
                store.selectStyleStore.openModal();
              }}
            >
              选品
            </Button>
          </Permission>
        ) : null
      }
    </>
  );
};

// 顶部操作tabs
const JoinStatus = [
  {
    key: '全部',
    value: '-1',
  },
  {
    key: '已加入',
    value: '1',
  },
  {
    key: '待加入',
    value: '0',
  },
  {
    key: '取消加入',
    value: '2',
  },
];

const ShowroomManage: React.FC<unknown> = observer(() => {
  const {
    programme,
    grid,
    awaitReturnNum,
    batchOrderStore,
    setSubareaStore,
    printStore,
    checkAwaitReturn,
    joinStatus,
    onTabKeyChange,
    batchReturnStore,
    exportStore,
    shoppingCartCount,
    currentShowroomId,
    openBatchOrderVisible,
    checkUnshelfStyle,
    unshelfNum,
    downloadAreaQrcode,
    downloadBtnLoading,
    selectStyleStore,
    tenantType,
  } = store;
  return (
    <div className={styles.pageContainer}>
      {
        !tenantType.includes('100027') ? (
          <div className={styles.tabContainer}>
            <Tabs
              activeKey={joinStatus}
              onChange={onTabKeyChange}
            >
              {JoinStatus.map((el) => {
                return (
                  <Tabs.TabPane
                    key={el.value}
                    tab={el.key}
                    tabKey={el.value}
                  />
                );
              })}
            </Tabs>
          </div>
        ) : null
      }
      <Card>
        <NormalProgrammeComponent store={programme}/>
      </Card>
      <div className={styles.btnSearch}>
        <div className={styles.notReturned}>
          {`待归还样衣共${awaitReturnNum ?? ' '}件`}
          <span
            className={styles.check}
            onClick={checkAwaitReturn}
          >
            立即查看
          </span>
        </div>
        <div className={styles.notReturned}>
          {`平台已下架共${unshelfNum ?? ' '}款`}
          <span
            className={styles.check}
            onClick={checkUnshelfStyle}
          >
            立即查看
          </span>
        </div>
      </div>
      <div className={styles.operation}>
        <Space>
          <Permission permissionId="75_370">
            <div
              className={`${styles.downloadBtn} ${downloadBtnLoading ? styles.downloadingBtn : ''}`}
              onClick={downloadBtnLoading ? null : downloadAreaQrcode}
            >
              {downloadBtnLoading && (
                <Spin
                  indicator={antIcon}
                  size="small"
                />
              )}
              <span className={styles.txt}>
                下载分区二维码
              </span>
            </div>
          </Permission>
          {batchBtns(joinStatus, tenantType)}
        </Space>
      </div>
      <div className={styles.content}>
        <MainSubStructure store={grid}/>
        {(joinStatus === '-1' || joinStatus === '1') && (
          <Permission permissionId="75_372">
            <div
              className={styles.batchCreateOrder}
              onClick={openBatchOrderVisible}
            >
              <img src={`${api.oss}/images/cart.png`}/>
              <div className={styles.badge}>
                {shoppingCartCount}
              </div>
            </div>
          </Permission>
        )}
      </div>
      <BatchOrder store={batchOrderStore}/>
      <SetSubareaModal store={setSubareaStore}/>
      <PrintModal store={printStore}/>
      <BatchReturn store={batchReturnStore}/>
      <ExportModal store={exportStore}/>
      <SelectStyle store={selectStyleStore}/>
    </div>
  );
});

export default ShowroomManage;
