import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import type { IObj } from 'egenie-utils';
import { EgGridModel, NormalProgramme } from 'egenie-utils';
import { action, observable, runInAction } from 'mobx';
import api from '../../../../utils/api';
import type ShowroomStore from '../../store';
import { COLUMNS, FILTER_ITEMS } from './constant';
import type { IEachRow, IGood } from './interface';

export class BatchReturnStore {
  constructor(parent: ShowroomStore) {
    this.parent = parent;
  }

  public parent: ShowroomStore;

  @observable private showroomId: number;

  @observable private initialGoods: IEachRow[] = [];// 初始化数据

  @observable public visible = false;// 弹窗显隐

  @observable public confirmLoading = false;// 确认退货按钮loading

  @observable public selectedIds: string[] = [];

  public programme = new NormalProgramme({
    count: 6,
    filterItems: FILTER_ITEMS,
    handleSearch: () => this.handleQuery(),
  });

  @action public toggleModal = (visibile: boolean, selectedIds: string[], showroomId?) => {
    this.visible = visibile;
    this.selectedIds = selectedIds;

    // 打开弹窗
    if (this.visible) {
      this.showroomId = showroomId;
      this.getArea();
      this.initData();
    } else {
      // 关闭
      this.initialGoods = [];
      this.programme.filterItems.reset();
      this.gridModel.rows = [];
      this.gridModel.resetCursorRow();
    }
  };

  // 查询
  @action private handleQuery = () => {
    return new Promise((resolve) => {
      const params = this.programme.filterItems.params || {};
      const {
        goodsName = '',
        vendorName = '',
        galleryAreaId = '',
      } = params;

      const resultList = this.initialGoods.filter((item) => {
        // 名称档口模糊匹配 分区全匹配
        return item.goodsName.includes(goodsName as string) && item.vendorName.includes(vendorName as string) && (galleryAreaId === '' || item.galleryAreaId === galleryAreaId);
      });
      this.gridModel.rows = resultList;
      resolve(resultList);
    });
  };

  // 初始化数据
  @action private initData = () => {
    this.gridModel.loading = true;
    request<BaseData<{ goodsList: IGood[]; shopTos; existFilter: boolean; }>>({
      url: '/api/gallery/rest/return/order/goods/list',
      method: 'POST',
      data: {
        galleryId: this.showroomId,
        goodsIds: this.parent.selectedRows.map((item) => item.goodsId),
      },
    })
      .then((res) => {
        runInAction(() => {
          if (res?.data?.existFilter) {
            message.warning('部分款式不可退，系统已自动过滤！');
          }
          res.data.goodsList.forEach((goodItem) => {
            goodItem.skuList.forEach((element) => {
              this.initialGoods.push({
                ...element,
                mainUrl: goodItem.mainUrl,
                galleryAreaId: goodItem.galleryAreaId,
                goodsName: goodItem.goodsName,
                vendorName: goodItem.vendorName,
                galleryAreaName: goodItem.galleryAreaName,
                num: element.skuReturnNum,
              });
            });
          });
          this.gridModel.rows = this.initialGoods;
          const vendorNameList = this.mapOptions(res.data.shopTos, 'shopName', 'shopName');
          this.programme.filterItems.addDict({ vendorName: vendorNameList });
        });
      })
      .finally(() => {
        this.gridModel.loading = false;
      });
  };

  public gridModel = new EgGridModel({
    columns: COLUMNS(this)
      .map((item) => ({
        resizable: true,
        ...item,
      })),
    rows: [],
    primaryKeyField: 'returnSkuRowDeleteId',
    showCheckBox: false,
    showPager: false,
  });

  // 删除数据
  @action public delete = (row) => {
    if (this.gridModel.rows?.length === 1) {
      message.warn('至少保留一条数据');
      return;
    }

    // 将原始数据删除
    this.initialGoods = this.initialGoods.filter((item) => item.returnSkuRowDeleteId !== row.returnSkuRowDeleteId);
    this.gridModel.rows = this.gridModel.rows.filter((item) => item.returnSkuRowDeleteId !== row.returnSkuRowDeleteId);
  };

  // 获取分区
  private getArea = () => {
    request<BaseData<[]>>({ url: `${api.queryAreaList}?galleryId=${this.showroomId}` })
      .then((res) => {
        const areaList = this.mapOptions(res.data, 'galleryAreaName', 'galleryAreaId');
        this.programme.filterItems.addDict({ galleryAreaId: areaList });
      });
  };

  @action
  public changeNum = (row: IObj, value: number) => {
    this.initialGoods.forEach((item) => {
      if (item.returnSkuRowDeleteId === row.returnSkuRowDeleteId) {
        item.num = value;
      }
    });
  };

  // 确认退货
  @action public submitReturn = () => {
    if (!this.gridModel.rows.length) {
      message.warn('请选择退货商品');
      return;
    }
    const skuParamList = this.gridModel.rows.map((item) => {
      return {
        goodsSkuId: item.skuId,
        returnNum: item.num,
      };
    });
    const data = {
      galleryId: this.showroomId,
      skuParamList,
    };
    this.confirmLoading = true;
    request<BaseData>({
      url: '/api/gallery/rest/return/order/create',
      method: 'POST',
      data,
    })
      .then((res) => {
        message.success('提交成功');
        this.toggleModal(false, []);
        window.top.egenie.openTab('/egenie-ts-gallery/galleryReturnOrder', 105, '展厅退货单');
      })
      .finally(() => {
        this.confirmLoading = false;
      });
  };

  // 映射选项
  private mapOptions = (data: Array<Record<string, string>>, name: string, val: string) => {
    return data.map((item) => ({
      label: item[name],
      value: item[val],
    }));
  };
}
