import { EgGrid } from 'egenie-utils';
import React from 'react';
import styles from './index.less';
import type ArriveLogStore from './store';

export const arriveLogModal = (logStore: ArriveLogStore): JSX.Element => {
  return (logStore ? (
    <div className={styles.content}>
      <div className={styles.title}>
        到货记录
      </div>
      <div className={styles.gridContent}>
        <EgGrid store={logStore.logGrid}/>
      </div>
    </div>
  ) : (
    <div>
      加载中...
    </div>
  ));
};
