import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import api from '../../../../utils/api';
import type { Area } from '../../interface';

export class SetSubareaStore {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public visible = false; // 弹窗显隐

  @observable public showNewEdit = false;

  @observable public name = ''; // 分区名称

  @observable public list: Area[] = []; // 分区列表

  @observable public galleryId = null; // 展厅id

  @observable public galleryGoodsIds = []; // 展厅款式ids

  @observable public areaId = ''; // 当前分区id

  @observable public loading = false; // 确认下单loading

  // 控制显隐
  @action public changeVisible = (visible: boolean, galleryId?: string, galleryGoodsIds?: string[]): void => {
    this.visible = visible;
    if (visible) {
      this.galleryId = galleryId;
      this.galleryGoodsIds = galleryGoodsIds;
      this.queryList();
    } else {
      this.areaId = '';
      this.name = '';
      this.list = [];
      this.showNewEdit = false;
      this.loading = false;
    }
  };

  @action public queryList = async(): Promise<void> => {
    const res: BaseData<Area[]> = await request({ url: `${api.queryAreaList}?galleryId=${this.galleryId}` });
    this.list = res.data.map((item) => {
      return {
        ...item,
        newName: '',
        isEdit: false,
      };
    });
  };

  @action public changeNewEditVisible = (visible: boolean): void => {
    this.showNewEdit = visible;
  };

  @action public handleCancelNewEdit = (): void => {
    this.name = '';
    this.showNewEdit = false;
  };

  @action public handleChangeName = (e, id?: string) => {
    if (id) {
      const idx = this.list.findIndex((item) => item.galleryAreaId === id);
      this.list[idx].newName = e.target.value;
    } else {
      this.name = e.target.value;
    }
  };

  @action public handleAdd = async(id?: string): Promise<void> => {
    let areaName = this.name;
    const idx = this.list.findIndex((item) => item.galleryAreaId === id);
    if (id && idx > -1) {
      areaName = this.list[idx].newName || this.list[idx].galleryAreaName;
    }
    if (!areaName) {
      message.warning('分区名称不能为空');
      return;
    }
    await request({
      url: api.editArea,
      method: 'POST',
      data: {
        areaName,
        galleryAreaId: id,
        galleryId: this.galleryId,
      },
    });
    if (id && idx > -1) {
      this.list[idx].isEdit = false;
    } else {
      this.showNewEdit = false;
      this.name = '';
    }
    this.parent?.getArea();
    message.success(id ? '编辑成功' : '创建成功');
    this.queryList();
  };

  @action public handleDelete = (id: string): void => {
    Modal.confirm({
      title: '确定要删除吗？',
      onOk: async() => {
        await request({ url: `${api.deleteArea}?areaId=${id}` });
        message.success('删除成功');
        if (this.areaId === id) {
          this.areaId = null;
        }
        this.queryList();
      },
    });
  };

  @action public onSave = async(): Promise<void> => {
    if (!this.areaId) {
      message.warning('请选择分区');
      return;
    }
    this.loading = true;
    try {
      await request({
        url: api.batchBindingArea,
        method: 'POST',
        data: {
          galleryAreaId: this.areaId,
          galleryGoodsIds: this.galleryGoodsIds,
        },
      });
      message.success('设置成功');
      this.changeVisible(false);
      this.parent?.onRefresh();
    } finally {
      this.loading = false;
    }
  };

  @action public onChangeSelectArea = (id: string): void => {
    this.areaId = id;
  };

  @action public handleEdit = (index: number): void => {
    if (this.list.some((item) => item.isEdit)) {
      message.warning('请先保存当前分区');
      return;
    }
    this.list[index].isEdit = true;
  };

  @action public handleCancelEdit = (index: number): void => {
    this.list[index].newName = '';
    this.list[index].isEdit = false;
  };

  /**
   * 设置分区显示与隐藏
   * @param index 数据索引
   */
  @action public handleShowOrHidden = (index: number): void => {
    const visible = this.list[index].visible === 0 ? 1 : 0;
    this.list[index].visible = visible;
    request({
      url: api.updateAreaVisible,
      method: 'GET',
      params: {
        areaId: this.list[index].galleryAreaId,
        visible: this.list[index].visible,
      },
    }).then(() => {
      this.parent?.getArea();
      message.success(visible === 1 ? '分区显示设置成功' : '分区隐藏设置成功');
    });
  };
}

