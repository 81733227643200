import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { MainSubStructureModel, NormalProgramme, request } from 'egenie-utils';
import { debounce } from 'lodash';
import { action, observable } from 'mobx';
import api from '../../../../utils/api';
import { FilterItems, TableColumns } from './constant';
import type { Area, Category, Goods, GoodsAndCategory } from './interface';
import AddressManageStore from './addressManager/store';

export class BatchOrderStore {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public showroomId: string; // 展厅id

  @observable public visible = false; // 弹窗显隐

  @observable public initialGoods: Goods[] = []; // 所有商品

  @observable public batchNum = null; // 批量修改数量

  @observable public totalNum = 0; // 全部数量

  @observable public loading = false; // 确认下单loading

  @observable public pageLoading = false;

  @observable public message = '';// 买家留言

  @observable public addressManageStore = new AddressManageStore();

  // 控制显隐
  @action
  public changeVisible = (visible: boolean, showroomId?: string) => {
    this.visible = visible;
    this.showroomId = showroomId;
    if (visible) {
      this.queryGoodList();
      this.getArea();
      this.addressManageStore.showRoomId = showroomId;
      this.addressManageStore.init();
    } else {
      this.normalProgramme.filterItems.reset();
      this.batchNum = '';
      this.initialGoods = [];
      this.mainSubStructureModel.gridModel.resetAll();
      this.parent?.getShoppingCartCount();
      this.handleQuery();
      this.addressManageStore.reset();
    }
  };

  public mainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: TableColumns(this)
        .map((item) => ({
          ...item,
          resizable: true,
        })),
      primaryKeyField: 'key',
      showCheckBox: false,
      showPager: false,
      showNormalEmpty: true,
      rowHeight: 72,
    },
    hiddenSubTable: true,
    api: {},
  });

  public normalProgramme = new NormalProgramme({
    count: 6,
    filterItems: FilterItems(),
    handleSearch: () => this.handleQuery(),
  });

  // 筛选
  @action
  public handleQuery = () => {
    const params = this.normalProgramme.filterItems.params || {};

    // 如果筛选项名称模糊搜索，类目选择项与列表中根节点和子节点id匹配
    this.mainSubStructureModel.gridModel.rows = this.initialGoods.filter((item) => (item.goodsName.includes(params.goodsName as string || '') &&
      (params.categoryId ? (`${params.categoryId}` === `${item.rootCategoryId}` || `${params.categoryId}` === `${item.goodsCategoryId}`) : true) &&
      (params.galleryAreaId ? params.galleryAreaId === item.galleryAreaId : true)));

    // 获取当前表格全部数量
    this.totalNum = this.initialGoods.map((item) => item.num)
      .reduce((prev, cur) => {
        return prev + cur;
      }, 0);
    return Promise.resolve();
  };

  // 获取选中项商品
  @action
  public queryGoodList = async() => {
    this.pageLoading = true;
    const req = await request<BaseData<GoodsAndCategory>>({
      url: '/api/gallery/rest/sale/order/goods/list',
      method: 'post',
      data: { galleryId: this.showroomId },
    });

    // 类目下拉
    const treeData = this.handleData(req?.data?.categoryList || []);
    this.normalProgramme.filterItems.updateFilterItem([
      {
        type: 'treeSelect',
        field: 'categoryId',
        treeData,
      },
    ]);

    // 初始list
    const list = req.data?.goodsList?.map((item) => {
      const skuItem = item.skuList?.find((el) => el.selected);
      return {
        ...item,
        key: item.shoppingCartId,
        colorSpec: skuItem?.colorSpec,
        sizeSpec: skuItem?.sizeSpec || null,
        skuPicUrl: skuItem?.skuPicUrl,
        skuId: skuItem?.skuId,
        price: skuItem?.price,
        num: item.num || 1,
      };
    });
    if (list) {
      this.initialGoods = [...list];
      this.mainSubStructureModel.gridModel.rows = [...list];
    } else {
      this.initialGoods = [];
      this.mainSubStructureModel.gridModel.rows = [];
    }
    this.handleQuery();
    this.pageLoading = false;
  };

  // 树形修改
  @action
  public handleData = (originData: Category[]) => {
    const data = originData.map((el) => {
      return {
        value: el.categoryId.toString(),
        title: el.categoryName,
        children: el.children.length > 0 ? this.handleData(el.children) : [],
      };
    });
    return data;
  };

  // 复制
  @action
  public copy = async(row): Promise<void> => {
    const res = await request({
      url: `/api/gallery/rest/sale/order/copyCartGoods/${row?.key}`,
      method: 'post',
    });
    this.queryGoodList();
    this.handleQuery();
  };

  // 删除
  @action
  public delete = debounce(async(row) => {
    this.pageLoading = true;
    const res = await request({
      url: `/api/gallery/rest/sale/order/removeCartGoods/${row?.key}`,
      method: 'post',
    });
    this.queryGoodList();
  }, 200);

  // 批量修改
  @action
  public batchChangeNumber = (value) => {
    this.batchNum = value;
  };

  // 确认修改数量
  @action
  public confirmChangeNumber = async() => {
    this.initialGoods.forEach((item) => {
      if (this.mainSubStructureModel.gridModel.rows.map((el) => el.key)
        .includes(item.key)) {
        item.num = this.batchNum;
      }
    });
    await request({
      url: `/api/gallery/rest/sale/order/batchEditCartGoodsNum/${this.batchNum}`,
      method: 'post',
    });
    this.queryGoodList();
    this.handleQuery();
  };

  // 改变规格一/规格二
  @action
  public changeSpecs = async(row, value: string, type: 'colorSpec' | 'sizeSpec') => {
    this.initialGoods.forEach((item) => {
      if (item.key === row.key) {
        type === 'colorSpec' ? item.colorSpec = value : item.sizeSpec = value;
        const skuItem = item.skuList?.find((el) => `${el.colorSpec}${el.sizeSpec}` === `${item.colorSpec}${item.sizeSpec}`);
        item.skuId = skuItem?.skuId;
        item.price = skuItem?.price;
        item.skuPicUrl = skuItem?.skuPicUrl;
      }
    });

    // 获取改变后的内容
    const cartItem = this.initialGoods?.find((el) => el.key === row?.key);
    if (!cartItem?.skuId) {
      return;
    }
    await request({
      url: '/api/gallery/rest/sale/order/editCartGoods',
      method: 'post',
      data: {
        cartId: cartItem?.key,
        goodsSkuId: cartItem?.skuId,
        num: cartItem?.num,
      },
    });
    await this.queryGoodList();
    this.handleQuery();
  };

  // 单个修改数量
  @action
  public changeNum = async(row, value) => {
    this.initialGoods.forEach((item) => {
      if (item.key === row.key) {
        item.num = value;
      }
    });
    const cartItem = this.initialGoods?.find((el) => el.key === row?.key);
    await request({
      url: '/api/gallery/rest/sale/order/editCartGoods',
      method: 'post',
      data: {
        cartId: cartItem?.key,
        goodsSkuId: cartItem?.skuId,
        num: cartItem?.num,
      },
    });
    await this.queryGoodList();
    this.handleQuery();
  };

  // 提交
  @action
  public save = async() => {
    const postData: any = this.initialGoods.map((item) => ({
      num: item.num,
      skuId: item.skuId,
    }));
    if (postData.some((item) => !item.skuId)) {
      message.warning('部分SKU已失效，请重新选择规格');
      return;
    }
    const addressInfo = this.addressManageStore.receiverInfo;
    if (!addressInfo) {
      return;
    }
    this.loading = true;
    try {
      const req = await request({
        url: '/api/gallery/rest/sale/order/create',
        method: 'post',
        data: {
          skuList: postData,
          message: this.message || '',
          addressParam: addressInfo,
        },
      });
      message.success('下单成功');
      this.changeVisible(false);
      this.parent?.getShoppingCartCount();
      this.parent.grid.gridModel.onQuery();
      this.addressManageStore.reset();
    } finally {
      this.loading = false;
    }
  };

  // 获取分区
  @action public getArea = async(): Promise<void> => {
    const res: BaseData<Area[]> = await request({ url: `${api.queryAreaList}?galleryId=${this.showroomId}` });
    const areaList = res.data?.map((el) => {
      return {
        label: el.galleryAreaName,
        value: `${el.galleryAreaId}`,
      };
    });
    this.normalProgramme.filterItems.addDict({ galleryAreaId: areaList });
  };
}

