import React from 'react';
import { Button, Space } from 'antd';
import { observer } from 'mobx-react';
import { EgGrid, FullModal } from 'egenie-utils';
import type Store from './store';
import { AddModal } from './addModal';
import { ModifyModal } from './modifyModal';
import styles from './index.less';

export const SelectStyle: React.FC<{ store: Store; }> = observer((props) => {
  const { visible, gridModel, closeModal, submitLoading, addModalStore, batchModify, batchDelete, modifyStore, selectCooperateProduct } = props.store;
  return (
    <FullModal
      onCancel={closeModal}
      operation={(
        <Space>
          <Button
            loading={submitLoading}
            onClick={selectCooperateProduct}
            type="primary"
          >
            提交
          </Button>
          <Button
            onClick={closeModal}
          >
            取消
          </Button>
        </Space>
      )}
      title="选品"
      visible={visible}
    >
      <div
        className={styles.contentWrapper}
      >
        <Space>
          <Button
            onClick={() => {
              addModalStore.openModal();
            }}
            size="small"
          >
            新增选品
          </Button>
          <Button
            onClick={() => {
              batchDelete();
            }}
            size="small"
          >
            批量删除
          </Button>
          <Button
            onClick={() => {
              batchModify();
            }}
            size="small"
          >
            批量修改
          </Button>
        </Space>
        <div style={{
          flex: 1,
          marginTop: '12px',
        }}
        >
          <EgGrid store={gridModel}/>
        </div>
      </div>
      {/* 添加选品 */}
      <AddModal store={addModalStore}/>
      {/* 批量修改 */}
      <ModifyModal store={modifyStore}/>
    </FullModal>
  );
});
